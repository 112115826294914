import { Component, Watch } from 'vue-property-decorator'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Container from 'typedi'
import NotificationService from '@/modules/common/services/notification.service'
import { mapState } from 'vuex'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Chart from 'chart.js'

interface IVTableHeader {
  text: string;
  align?: string;
  searchable?: boolean;
  sortable?: boolean;
  value?: string;
  width?: string;
}

type ExportType = 'links' | 'averages' | 'downloads' | 'speaker-scores' | 'all-answers' | 'all-surveyed-participants' | 'sessions-highlights' | 'all-certificates-transcripts'

interface DownloadProgress {
  total_records: number;
  processed_records: number;
}

type DownloadStatus = 'QUEUED' | 'RUNNING' | 'FINISHED' | 'FAILED' | 'NO_CONTENT'

@Component({
  name: 'GtrSurveysQuizzesView',
  computed: {
    ...mapState('surveys', ['surveysSummaries', 'certificates', 'lastCertExport', 'surveysStats']),
    ...mapState('event', ['event', 'eventAllContent'])
  }
})
export default class GtrSurveysQuizzesView extends GtrSuper {
  data () {
    return {
      lastCertExportData: {},
      certificateExportsByRegTypeChart: null
    }
  };

  surveysSummaries!: any[];
  surveysStats!: any;
  certificates!: any[];

  // eventAllContent from global state.
  eventAllContent!: any;

  // local state for session search
  sessionSearch: string | null = null

  // headers for the session table
  sessionTableHeaders: IVTableHeader[] = [
    {
      text: 'Session',
      value: 'name'
    },
    {
      text: 'Category',
      value: 'category'
    },
    {
      text: 'Room',
      value: 'room'
    },
    {
      text: 'Start',
      value: 'start_date'
    },
    {
      text: 'End',
      value: 'end_date'
    },
    {
      text: 'Attendees',
      value: 'unique_scans'
    },
    {
      text: 'Emails Sent',
      value: 'survey_emails_count'
    },
    {
      text: 'Completed',
      value: 'surveys_filled_count'
    },
    {
      text: '% Comp.',
      value: 'percent_complete'
    },
    {
      text: '',
      value: 'actions'
    }
  ]

  downloading = false

  downloadProgress: DownloadProgress = {
    total_records: 0,
    processed_records: 0
  }

  async mounted () {
    try {
      await this.fetchSessions()
      await this.fetchCertificates()
      this.loadLatestCertExport()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  @Watch('lastCertExport')
  onLastCertExportChange (payload: any) {
    this.$data.lastCertExportData = payload
  }

  private async fetchSessions () {
    try {
      await this.$store.dispatch('common/showLoader', { value: true })
      await this.$store.dispatch('surveys/fetchSurveysSummaries', {
        event_uuid: this.$route.params.event_uuid
      })
      const surveysStats = await this.$store.dispatch('surveys/fetchSurveysStats', this.$route.params.event_uuid)
      if (surveysStats?.certificate_exports_by_reg_type) {
        this.updateCertificateExportsByRegTypeChart(surveysStats)
      }
    } catch (error) {
      if (error.data.error_code === 'CONTENT_NOT_FOUND') {
        Container.get(NotificationService).warning('No session survey found.')
      }
      Container.get(ErrorHandlerService).error(error)
    } finally {
      await this.$store.dispatch('common/hideLoader')
    }
  }

  get eventTimezone (): string {
    return this.$store.state.event?.event?.timezone ?? 'UTC'
  }

  get surveysPortalURL (): string {
    if (this.eventAllContent) {
      return `${process.env.VUE_APP_REG_URL}/${this.eventAllContent.event.event_identifier}/surveys`
    }
    return ''
  }

  private async requestExport (exportType: ExportType) {
    try {
      this.downloading = true
      if (exportType === 'all-surveyed-participants') {
        const response = await this.$store.dispatch('surveys/fetchAllSurveyedParticipantsExport', this.$route.params.event_uuid)
        this.checkURLAndGo(response.data)
      } else if (exportType === 'all-answers') {
        const response = await this.$store.dispatch('surveys/fetchAllAnswersExport', { event_uuid: this.$route.params.event_uuid, session_uuid: undefined })
        this.checkURLAndGo(response.data)
      } else if (exportType === 'sessions-highlights') {
        const response = await this.$store.dispatch('surveys/fetchSessionsHighlightsExport', { event_uuid: this.$route.params.event_uuid })
        this.checkURLAndGo(response.data)
      } else if (exportType === 'all-certificates-transcripts') {
        const response = await this.exportAllCertificatesAndTranscripts()
        switch (response.data.status) {
          case 'FINISHED':
            window.location.href = response.data.s3_url
            break
          case 'NO_CONTENT':
            Container.get(NotificationService).warning('No results')
            break
        }
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.downloading = false
    }
  }

  async exportAnswers (session_uuid) {
    const response = await this.$store.dispatch('surveys/fetchAllAnswersExport', { event_uuid: this.$route.params.event_uuid, session_uuid })
    this.checkURLAndGo(response.data)
  }

  private async fetchCertificates () {
    try {
      const payload = {
        event_uuid: this.$route.params.event_uuid
      }
      const response = await this.$store.dispatch('surveys/getEventCertificates', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  async exportAllCertificatesAndTranscripts () {
    try {
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        certificate_uuid: this.certificates[0].uuid
      }
      this.$store.dispatch('surveys/startAllCertificatesAndTranscriptsExport', payload)
      let response, currentResultStatus: DownloadStatus
      do {
        await new Promise(resolve => setTimeout(resolve, 1000))
        response = await this.$store.dispatch('surveys/getAllCertificatesAndTranscriptsExport', payload)
        currentResultStatus = response.data.status
        this.downloadProgress = response.data.status_details
      }
      while (currentResultStatus !== 'FINISHED' && currentResultStatus !== 'NO_CONTENT' && currentResultStatus !== 'FAILED')
      this.downloadProgress = {
        total_records: 0,
        processed_records: 0
      }
      return response
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  async loadLatestCertExport () {
    try {
      if (this.certificates.length === 0) return
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        certificate_uuid: this.certificates[0].uuid
      }
      let response, currentResultStatus: DownloadStatus
      do {
        await new Promise(resolve => setTimeout(resolve, 5000))
        response = await this.$store.dispatch('surveys/getAllCertificatesAndTranscriptsExport', payload)
        currentResultStatus = response.data.status
        this.downloadProgress = response.data.status_details
        this.$store.commit('surveys/SET_LAST_CERT_EXPORT', response.data)
      }
      while (currentResultStatus !== 'FINISHED' && currentResultStatus !== 'NO_CONTENT' && currentResultStatus !== 'FAILED')
      this.downloadProgress = {
        total_records: 0,
        processed_records: 0
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  /**
   * Convert HSL color value to hex
   * @param {number} h Between 0 and 360
   * @param {number} s Between 0 and 100
   * @param {number} l Between 0 and 100
   */
  hslToHex (h: number, s: number, l: number): string {
    l /= 100
    const a = s * Math.min(l, 1 - l) / 100
    const f = n => {
      const k = (n + h / 30) % 12
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
      return Math.round(255 * color).toString(16).padStart(2, '0') // convert to Hex and prefix "0" if needed
    }
    return `#${f(0)}${f(8)}${f(4)}`
  }

  /**
   * Generates an array of n colors evenly spaced along the spectrum
   * @param {number} n
   */
  nColors (n: number): string[] {
    const interval = 360 / n // distribute the colors evenly on the hue range
    const colors: string[] = []
    for (let i = 0; i < n; i++) {
      colors.push(this.hslToHex(interval * i, 100, 50)) // you could also alternate the saturation and value for even more contrast between the colors
    }
    return colors
  }

  updateCertificateExportsByRegTypeChart (newValue) {
    const { certificate_exports, certificate_exports_by_reg_type } = newValue
    const labels: string[] = []
    const data: any[] = []
    let counter = 0
    for (const regTypeData of certificate_exports_by_reg_type) {
      const { reg_type = '', count = 0 } = regTypeData
      labels.push(Math.round(count / certificate_exports * 100) + '% ' + reg_type)
      data.push(count)
      counter++
    }
    const ctx = document.getElementById('certificates-exported-chart') as any
    if (this.$data.certificateExportsByRegTypeChart) {
      this.$data.certificateExportsByRegTypeChart.destroy()
    }
    this.$data.certificateExportsByRegTypeChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels,
        datasets: [
          {
            data,
            backgroundColor: this.nColors(counter)
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'right'
        },
        title: {
          text: 'Certificates Exported by Registration Type',
          display: true,
          fontSize: 18,
          fontFamily: 'Open Sans'
        }
      },
      plugins: [
        {
          // id: 'custom_text',
          beforeDraw: chart => {
            const ctx = chart!.canvas!.getContext('2d')
            if (ctx) {
              ctx.save()
              const centerX =
                (chart.chartArea.left + chart.chartArea.right) / 2
              const centerY =
                (chart.chartArea.top + chart.chartArea.bottom) / 2
              const fontSize = 24
              ctx.font = `bold ${fontSize}px sans-serif`
              ctx.textAlign = 'center'
              ctx.textBaseline = 'middle'
              ctx.fillStyle = '#565656'
              ctx.fillText(certificate_exports, centerX, centerY)
              ctx.restore()
            }
          }
        }
      ]
    })
  }
}
